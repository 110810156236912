@charset "UTF-8";

.headerTab_menuIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: pointer;
}
.headerTab_menuIcon img {
  height: 25px;
  display: inline-block;
  margin-left: 10px;
}
.headerTab_menuIcon .changeBlack {
  color: #000;
  height: 25px;
  display: inline-block;
  margin-left: 10px;
}

.headerTab_menuIcon.active {
  color: #000;
  background: rgb(255, 165, 200);
  background: linear-gradient(
    180deg,
    rgb(255, 165, 200) 5px,
    rgba(255, 165, 200, 0) 5%
  );
}

.coopbbs_back {
  background: #f4f4f4;
  height: 100vmax;
}

.coopbbs_block_wrap {
  padding: 30px;
  background: #f4f4f4;
}

.headerTab ul {
  display: flex;
  height: 52px;
  background: #fff;
  color: #8b8b8b;
  border-bottom: 1px solid #e8e8e8;
}

.gfs_ul_space {
  padding-left: 0;
}
