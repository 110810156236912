@charset "UTF-8";

.gfs_category_frame {
  display: inline-block;
  margin: 2px;
  float: left;
}

.gfs_category_box {
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  background-color: white;
  text-align: center;
  padding: 5px 15px;
}

.gfs_category_box_checked {
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  background-color: #ffeaf2;
  text-align: center;
  padding: 5px 15px;
}
