@charset "UTF-8";

.boardPanel_wrap {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  margin: 0 30px;
}

.boardPanel_title {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px;
  font-weight: bold;
}

.boardPanel_title.everyones {
  background: #ffd6d6;
}

.clickable {
  cursor: pointer;
}

/* .clickable::after {
    font-family: "Font Awesome 5 Free";
    content: "\f054";
    position: absolute;
    right: 10px;
  } */

/* GFS追加　- ここから　- */
.gfs_arrow_position {
  margin-left: auto;
}
/* GFS追加　- ここまで- */

.boardPanel_2column {
  display: flex;
}

.boardPanel_date-label {
  width: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px dashed #b9b9b9;
}

.boardPanel_date {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
}

hr.boardPanel_divider {
  margin: 0;
  border-top: 1px solid #b9b9b9;
}

.boardPanel_item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
}

boardPanel_description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.boardPanel_label-s {
  display: block;
  font-size: 14px;
  color: #8b8b8b;
}
