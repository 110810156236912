@charset "UTF-8";

.pageTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 16px;
  font-size: 20px;
}

.pageTitle_color-list {
  background: #fde4ce;
}

.pageTitle_color-everyones {
  background: #ffd6d6;
}

.pageTitle_color-myrequest {
  background: #f0d2f7;
}

.pageTitle_color-regist {
  background: #ffeaf2;
}

.pageTitle .backArrow {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 6px;
  height: 30%;
  width: 40px;
}

.registInput_kanaColumn-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 20px;
}

.registInput_kanaColumn {
  text-decoration: underline;
  font-size: 18px;
  cursor: pointer;
}
.registInput_kanaColumn.current {
  font-weight: bold;
  text-decoration: none;
  cursor: inherit;
}
