@charset "UTF-8";

.content_all {
  width: 100%;
  height: 100vmax;
  background: #ffffff;
}

.content_inner {
  text-align: center;
  background-color: #ffffff;
  padding-bottom: 0px;
}

.full_width {
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

.qr_black_bar {
  background: #3f3b3a;
  height: 65px;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.camera_icon_circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid #000000;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  /* position: relative; */
}

.fa {
  font-size: 30px;
}

/* GFS追加 --ここから-- */
.header {
  background: #f4f4f4;
}

.header .title {
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.selectWrap {
  position: relative;
}
.selectWrap select {
  width: 100%;
  border-color: darkgray;
  background-color: #ffffff;
  border-radius: 8px;
  height: 36px;
  padding: 0 10px;
  margin-bottom: 15px;
  appearance: none;
  color: #000000;
}
.selectWrap:after {
  content: url("../../../../img/accrodionArrow_green.svg");
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  width: 0;
  z-index: 0;
  position: absolute;
  top: 19%;
  right: 35px;
  /* transform: translateY(-50%); */
}

.info_instead_camera {
  height: 190px;
  background-color: #c2c2c2;
  align-items: center;
  display: flex;
  justify-content: center;
}

.faAngleRight {
  position: absolute;
  right: 20px;
  color: #fff;
  display: inline-block;
  font-weight: bolder;
  font-size: 23px;
}
.clickable {
  cursor: pointer;
}
/* GFS追加 -----ここまで---- */

.join_bar_block {
  margin: 0 30px;
}

.total_join_bar {
  margin-bottom: 15px;
  display: flex;
  text-align: left;
  height: 40px;
}

.join_name {
  padding: 10px;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  border-radius: 10px 0 0 10px;
  width: 75%;
}

.bg_red {
  background: #ffa5c8;
}

.bg_green {
  background: #87bb57;
}

.bg_blue {
  background: #4b98c7;
}

.bg_beige {
  background: #b8956c;
}
.border_red {
  border: 1px solid #ffa5c8;
  border-radius: 10px;
}

.border_green {
  border: 1px solid #87bb57;
  border-radius: 10px;
}

.reload_btn {
  margin-top: 20px;
}

.reload_btn img {
  width: 50px;
  height: 50px;
  filter: drop-shadow(0 3px 6px #aeaeae);
}

.btn_wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.btn_green {
  border-radius: 100px;
  background: #87bb57;
  padding: 17px 0;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  display: block;
  position: relative;
  width: 100%;
  height: 60px;
  margin: 0 30px;
}

.icon-pic {
  width: 25px;
  height: 52px;
}

.join_total {
  padding: 5px;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-left: auto;
  font-size: 18px;
  font-weight: bold;
  background: #ffffff;
  border-radius: 0 10px 10px 0;
  width: 100%;
}

.qr_code_border {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}

.qr_reader_cameraBorder {
  position: fixed;
  width: 200px;
  height: 200px;
  padding-top: 20px;
  border: solid 3px black;
  background-color: white;
  margin: 0 auto;
  opacity: 0.5;
}

.code_data {
  display: none;
}

.btn_dummy {
  width: 100px;
  margin: 0 10px;
}

.btn_manual_camera {
  width: 100px;
  display: flex;
  color: #fff;
  background: #7a7a7a;
  border-radius: 100vh;
  padding: 6px 25px;
  margin-left: 10px;
  font-size: 15px;
  text-align: center;
  margin-right: 10px;
}
