@charset "UTF-8";

.mg_t_20 {
  margin-top: 20px;
}

.text_14 {
  font-size: 87.5%;
}

.content_all {
  width: 100%;
}

.initialize_back {
  background: #feeaf2;
}

.content_inner {
  max-width: 400px;
  margin: 0 auto;
  position: relative;
}

.article_margin {
  width: min(100%, 500px);
  margin: auto;
}

.coopbbs_block_wrap {
  padding: 30px;
  /* min-height: 530px; */
  height: 100vh;
}

.introductionTitle {
  font-weight: bold;
  /* gfs追加ここから */
  text-align: left;
  /* gfs追加ここまで */
}

hr.boardPanel_divider,
hr.detailPanel_hr {
  margin: 0;
  border-top: 1px solid #b9b9b9;
}

hr.divider {
  width: 100%;
  margin: 0;
  border-top: 1px solid #b9b9b9;
}
hr.divider.dashed {
  border-style: dashed;
}

.registTextbox textarea,
.introduceTextbox textarea,
.registTextbox .registInputText {
  width: 100%;
  padding: 10px;
  line-height: 1.6;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
}

.registTextbox_title-wrap {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.registTextbox_title-wrap .requireText {
  margin-left: auto;
  font-size: 14px;
}

.registTextbox_title-wrap .require {
  font-size: 14px;
  color: #8b8b8b;
}

.registTextbox_title-wrap .title,
.registPanel_title .title {
  font-weight: bold;
}

.registTextbox_title-wrap .require,
.registPanel_title .require {
  font-size: 14px;
  font-weight: normal;
}

.registTextbox_title-wrap .require {
  color: #8b8b8b;
}

.coopbbsButton_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.coopbbsButton_wrap.column {
  flex-direction: column;
  gap: 20px;
}

.coopbbsButton_wrap.row {
  flex-direction: row;
  gap: 10px;
}

.coopbbsButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px 0 20px;
  border-radius: 50px;
  text-align: center;
  font-weight: bold;
}
.coopbbsButton::after {
  position: absolute;
  right: 5%;
  content: "";
  width: 20px;
  height: 20px;
  background: right url("../../../../img/nextArrow-white.svg") no-repeat;
}
.coopbbsButton.size_l {
  width: 310px;
  height: 60px;
  font-size: 18px;
}

.coopbbsButton.color_pink {
  color: #fff;
  background-color: #ffa5c8;
}

.coopbbsButton.gfs_color_gray {
  color: #fff;
  background-color: #b9b9b9;
}

.gfs_text_initial_display {
  text-align: left;
  font-size: 14px;
}

.gfs_text_initial_display_example_sentence {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
}

.gfs_text_area {
  height: 150px;
}

.gfs_requireTextTitle {
  text-align: right;
}

.dialog_button {
  display: flex;
  margin-top: 30px;
  border: 1px solid #b9b9b9;
  border-radius: 10px;
  overflow: hidden;
  background: #b9b9b9;
  gap: 1px;
  width: 100px;
  margin: auto;
  margin-top: 30px;
}

.dialog_button button {
  flex: 1;
  /* gfs削除ここから */
  /* min-width: 145px; */
  /* gfs削除ここまで */
  background: #fff;
  color: #4792e0;
  font-size: 16px;
  text-align: center;
  padding: 10px;
}

.dialog_message {
  margin-top: 10px;
  /* gfs削除ここから */
  /* text-align: center; */
  /* gfs削除ここまで */
  /* gfs追加ここから */
  text-align: left;
  font-weight: bold;
  margin-left: 20px;
  margin-right: 10px;
  /* gfs追加ここまで */
}
