@charset "UTF-8";

.content_all {
  width: 100%;
}

.event_back,
.lifelog_back,
.passport_pack {
  background: #f4f4f4;
  height: 100vh;
}

.coopbbs_block_wrap {
  padding: 30px;
  /* min-height: 530px; */
}

.topCoop_menu_wrap {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.topCoop_menu_flex {
  display: flex;
  gap: 10px;
}
.topCoop_menu_flex.first {
  flex-direction: row;
}
.topCoop_menu_flex.second {
  flex-direction: column;
}

.topCoop_menu_flex .topmenu {
  display: flex;
  border-width: 3px;
  border-style: solid;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
  overflow: hidden;
}
.topCoop_menu_flex .topmenu.first {
  flex-direction: column;
}
.topCoop_menu_flex .topmenu .topmenu_blocks {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: #fff;
}
.topCoop_menu_flex .topmenu.first .topmenu_blocks {
  flex-direction: column;
}
.topCoop_menu_flex .topmenu.second .topmenu_blocks {
  flex-direction: row;
}
.topCoop_menu_flex .topmenu .topmenu_arrow {
  flex: 0 0 50px;
  background-repeat: no-repeat;
  background-size: 25px;
  background-position-y: center;
  /* background-image: url("../../images/nextArrow-white.svg"); */
}
.topCoop_menu_flex .topmenu.first .topmenu_arrow {
  background-position-x: 58%;
}
.topCoop_menu_flex .topmenu.second .topmenu_arrow {
  background-position-x: 76%;
}
.topCoop_menu_flex .topmenu.second .menuSecond_action_wrap {
  display: flex;
  flex-direction: row;
  width: 170px;
}

.topCoop_menu_flex .topmenu.second .menuSecond_action_wrap .noticeActionIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 50%;
}

.topCoop_menu_flex
  .topmenu.second
  .menuSecond_action_wrap
  .noticeActionIcon
  .boardPanel_status-numzero,
.topCoop_menu_flex
  .topmenu.second
  .menuSecond_action_wrap
  .noticeActionIcon
  .boardPanel_status-numtrue {
  margin-left: 0;
}

.boardPanel_nameProfile,
.requester_nameProfile,
.chat_nameProfile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.requester_nameProfile {
  position: relative;
}

.requester_nameIcon {
  flex: 0 0 80px;
  /* -webkit-mask-image: url(../images/mask.svg); */
  /* mask-image: url(../images/mask.svg); */
  -webkit-mask-size: contain;
  mask-size: contain;
  height: 80px;
  width: 80px;
}
.requester_nameIcon img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border-style: solid;
  border-color: #707070;
  border-width: 1px;
}

.coopbbsButton-topmenu-name {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.coopbbsButton-topmenu-wrap {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.coopbbsButton-topmenu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* gfs追加・修正ここから */
  padding: 0 5px 0 10px;
  margin: 0 -7px 0 0px;
  /* gfs追加・修正ここまで */
  border-radius: 50px;
  text-align: center;
  background: #fff;
  color: inherit;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
}
.coopbbsButton-topmenu::after {
  position: absolute;
  right: 3px;
  content: "";
  width: 15px;
  height: 15px;
  background: right url("../../../../img/rightArrow-pink.svg") no-repeat;
}
.coopbbsButton-topmenu.size_s {
  height: 30px;
  font-size: 12px;
}

.topCoop_menu_flex .topmenu {
  display: flex;
  border-width: 3px;
  border-style: solid;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
  overflow: hidden;
}
.topCoop_menu_flex .topmenu.first {
  flex-direction: column;
  border-color: #fd1744;
}
.topCoop_menu_flex .topmenu .topmenu_blocks {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: #fff;
}
.topCoop_menu_flex .topmenu.first .topmenu_blocks {
  flex-direction: column;
}
.topCoop_menu_flex .topmenu.second .topmenu_blocks {
  flex-direction: row;
}
.topCoop_menu_flex .topmenu .topmenu_arrow {
  flex: 0 0 50px;
  background-repeat: no-repeat;
  background-size: 25px;
  background-position-y: center;
  background-image: url("../../../../img/nextArrow-white.svg");
}
.topCoop_menu_flex .topmenu.first .topmenu_arrow {
  background-position-x: 58%;
}
.topCoop_menu_flex .topmenu.second .topmenu_arrow {
  background-position-x: 76%;
}
/* .topCoop_menu_flex .topmenu.second .menuSecond_action_wrap {
  display: flex;
  flex-direction: row;
  width: 170px;
}
.topCoop_menu_flex .topmenu.second .menuSecond_action_wrap .noticeActionIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 50%;
}
.topCoop_menu_flex
  .topmenu.second
  .menuSecond_action_wrap
  .noticeActionIcon
  .boardPanel_status-numzero,
.topCoop_menu_flex
  .topmenu.second
  .menuSecond_action_wrap
  .noticeActionIcon
  .boardPanel_status-numtrue {
  margin-left: 0;
} */

.menuFirst_desc {
  font-size: 14px;
}

.topmenu_title {
  flex: 1 0 auto;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
}
.topmenu_title.first {
  font-size: 22px;
}
.topmenu_title.second {
  flex-basis: 75px;
  font-size: 18px;
}

.boardPanel_status-numtrue,
.boardPanel_status-numzero,
.boardPanel_actionChat-unread,
.requester-unread {
  display: inline-block;
  min-width: 60px;
  margin-left: auto;
  color: #fff;
  font-weight: bold;
  padding: 3px 16px;
  border-radius: 50px;
  text-align: center;
}

.boardPanel_status-numtrue,
.boardPanel_actionChat-unread,
.requester-unread {
  background-color: #fd1744;
}

.topCoop_menu_flex
  .topmenu.second
  .menuSecond_action_wrap
  .noticeActionIcon
  .boardPanel_status-numzero,
.topCoop_menu_flex
  .topmenu.second
  .menuSecond_action_wrap
  .noticeActionIcon
  .boardPanel_status-numtrue {
  margin-left: 0;
}

.boardPanel_status-numzero {
  color: #fd1744;
  background-color: #fff;
  border: 1px solid #fd1744;
}

.text_12 {
  font-size: 75%;
}

.gfs_clickable_icon_position {
  margin: 13px;
  padding-left: -5px;
}

.gfs_clickable_icon {
  height: 15px;
  width: 15px;
  margin-left: 5px;
  padding: 2px;
}

.gfs_clickable_icon_horizontal {
  height: 30px;
  width: 30px;
  margin: auto;
  margin-top: 10px;
  display: block;
}

.gfs_clickable_icon_vertical {
  height: 30px;
  width: 30px;
  margin: auto;
  margin-top: 35px;
  display: block;
}

.make_transparent {
  opacity: 0;
}

.pd_tb_10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pd_lr_30 {
  padding-left: 30px;
  padding-right: 30px;
}

.topCoop_otherInfo {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  padding: 8px;
  color: inherit;
  border-width: 3px;
  border-style: solid;
  border-radius: 8px;
  border-color: #87BB57;
  background-color: #f6ffef;
}

.mg_b_30 {
  margin-bottom: 30px;
}

.text_14 {
  font-size: 87.5%;
}
@media screen and (min-width: 768px), print {
  .text_14 {
    font-size: 87.5%;
  }
}

.line_h_ss {
  line-height: 1.3;
}

.topmenu_arrowInfo {
  flex: 0 0 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 12px;
  background-position-x: 90%;
  background-position-y: center;
  background-image: url("../../../../img/nextArrow-white.svg");
  background-color: #87BB57;
  border-radius: 10px;
}
