@charset "UTF-8";

.selectBoxWrap {
  position: relative;
  display: flex;
  margin: 10px 0;
  width: 100%;
  justify-content: flex-end;
  /* セレクトボックスの矢印デザイン変更 */
}
.selectBoxWrap.adjust_mt {
  margin-top: -20px;
}
.selectBoxWrap select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* デフォルトの矢印を非表示 */
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  height: 30px;
  padding-left: 10px;
  padding-right: 30px;
  font-size: 14px;
}
.selectBoxWrap select::-ms-expand {
  display: none; /* デフォルトの矢印を非表示(IE用) */
}
/* .selectBoxWrap select {
  background-image: url("../images/selectboxArrow.svg");
  background-position: right 6px center;
  background-repeat: no-repeat;
  background-size: 18px 18px;
} */

.boardPanel_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 30px 30px 30px;
}

.boardPanel_wrap {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
  overflow: hidden;
}

.boardPanel_title,
.registPanel_title {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.boardPanel_title {
  padding: 15px;
}

.clickable {
  cursor: pointer;
}

/* .clickable::after {
  font-family: "Font Awesome 5 Free";
  font-size: 24px;
  font-weight: bold;
  content: "\f105";
  position: absolute;
  right: 10px;
} */

.boardPanel_accordionDetail,
.boardPanel_action {
  border-radius: 5px;
  padding: 10px;
  background: #ffeaf2;
}

.boardPanel_accordionDetail.myrequest {
  background: #ffeaf2;
}

.boardPanel_title.myrequest {
  background: #f0d2f7;
}

.boardPanel_profileAction {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 15px;
}

.boardPanel_2column {
  display: flex;
}

.boardPanel_date-label {
  width: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px dashed #b9b9b9;
}

.boardPanel_date {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
}

hr.boardPanel_divider,
hr.detailPanel_hr {
  margin: 0;
  border-top: 1px solid #b9b9b9;
}

.selectBoxWrap {
  position: relative;
  display: flex;
  margin: 10px 0;
  width: 100%;
  justify-content: flex-end;
  /* セレクトボックスの矢印デザイン変更 */
}
.selectBoxWrap.adjust_mt {
  margin-top: -20px;
}
.selectBoxWrap select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* デフォルトの矢印を非表示 */
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  height: 30px;
  padding-left: 10px;
  padding-right: 30px;
  font-size: 14px;
}
.selectBoxWrap select::-ms-expand {
  display: none; /* デフォルトの矢印を非表示(IE用) */
}
.selectBoxWrap select {
  /* background-image: url("../images/selectboxArrow.svg"); */
  background-position: right 6px center;
  background-repeat: no-repeat;
  background-size: 18px 18px;
}

.boardPanel_item,
.registPanel_item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
}

.boardPanel_nameProfile,
.requester_nameProfile,
.chat_nameProfile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.boardPanel_nameIcon,
.chat_nameIcon {
  flex: 0 0 50px;
  -webkit-mask-image: url(../../../../img/mask.svg);
  mask-image: url(../../../../img/mask.svg);
  -webkit-mask-size: contain;
  mask-size: contain;
  height: 50px;
  width: 50px;
}
.boardPanel_nameIcon img,
.chat_nameIcon img {
  height: 50px;
  width: 50px;
}

.boardPanel_accordionDetail.myrequest {
  background: #ffeaf2;
}

.boardPanel_accordionLabel,
.boardPanel_actionLabel {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.boardPanel_accordionLabel img,
.boardPanel_actionLabel img {
  width: 30px;
  display: inline-block;
  margin-right: 12px;
}

.boardPanel_accordionLabel img.filter {
  width: 24px;
}

.boardPanel_accordionLabel::after,
.boardPanel_actionLabel::after {
  position: absolute;
  right: 0;
  content: "";
  width: 20px;
  height: 20px;
}

.boardPanel_actionLabel::after {
  /* background: right url("../../images/nextArrow.svg") no-repeat; */
}

.boardPanel_accordionLabel::after {
  /* background: right url("../../images/accrodionArrow.svg") no-repeat; */
  transition: transform 0.3s;
  transform: rotate(0);
}

.open.boardPanel_accordionLabel::after {
  transform: rotate(180deg);
}

.boardPanel_accordionDetail-text {
  display: none;
  opacity: 0;
  visibility: hidden;
  line-height: 0;
  margin-top: 0;
  background: #fff;
  border-radius: 5px;
  transition: all 0.3s;
}

.open.boardPanel_accordionDetail-text {
  display: block;
  opacity: 1;
  visibility: visible;
  line-height: 1.5;
  margin-top: 10px;
}

.boardPanel_accordionDetail-item {
  padding: 0;
}

.open > .boardPanel_accordionDetail-item {
  padding: 10px;
}

.boardPanel_accordionDetail-sendDate,
.commentToSupporter-sendDate {
  font-size: 14px;
  color: #8b8b8b;
}

.boardPanel_accordionLabel,
.boardPanel_actionLabel {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.boardPanel_accordionLabel img,
.boardPanel_actionLabel img {
  width: 30px;
  display: inline-block;
  margin-right: 12px;
}

.boardPanel_accordionLabel img.filter {
  width: 24px;
}

.boardPanel_accordionLabel::after,
.boardPanel_actionLabel::after {
  position: absolute;
  right: 0;
  content: "";
  width: 20px;
  height: 20px;
}

.boardPanel_actionLabel::after {
  background: right url("../../../../img/nextArrow.svg") no-repeat;
}

.boardPanel_accordionLabel::after {
  background: right url("../../../../img/accrodionArrow.svg") no-repeat;
  transition: transform 0.3s;
  transform: rotate(0);
}

.boardPanel_action-cancel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding-left: 20px;
  color: #8b8b8b;
}
.boardPanel_action-cancel::before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  background: center url("../../../../img/icon_action_cancel.svg") no-repeat;
  left: 0;
}

/* GFS追加　- ここから　- */
.gfs_arrow_position {
  margin-left: auto;
}
/* GFS追加　- ここまで- */
