@charset "UTF-8";

.content_inner {
  max-width: 400px;
  margin: 0 auto;
  position: relative;
}

.subtitle {
  margin-left: -10px;
  margin-right: -10px;
}

.registInputboxWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}

.radioButton_flex-wrap {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.radioButton_flex-wrap input[type="radio"] {
  display: none;
}
.radioButton_flex-wrap input[type="radio"]:checked + label {
  background: #ffd9ee;
}
.radioButton_flex-wrap label.radioButton {
  flex: 1;
  padding: 12px;
  background: #fff;
  text-align: center;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  cursor: pointer;
}

.registTextbox textarea,
.introduceTextbox textarea,
.registTextbox .registInputText {
  width: 100%;
  padding: 10px;
  line-height: 1.6;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
}

.registTextbox_title-wrap {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.registTextbox_title-wrap .requireText {
  margin-left: auto;
  font-size: 14px;
}

.registTextbox_title-wrap .require {
  font-size: 14px;
  color: #8b8b8b;
}

.registTextbox_title-wrap .title,
.registPanel_title .title {
  font-weight: bold;
}

.registTextbox_title-wrap .require,
.registPanel_title .require {
  font-size: 14px;
  font-weight: normal;
}

.registTextbox_title-wrap .require {
  color: #8b8b8b;
}

.gfs_button_selected {
  flex: 1;
  padding: 12px;
  background: #ffd9ee;
  text-align: center;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  cursor: pointer;
}

.gfs_personal_button_selected {
  flex: 1;
  padding: 12px;
  background: #ffd9ee;
  text-align: center;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  cursor: pointer;
}

.gfs_juridical_button_selected {
  flex: 1;
  padding: 12px;
  background: #ffd9ee;
  text-align: center;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  cursor: pointer;
}

.gfs_juridical_inputed_invisible {
  display: none;
}

.gfs_juridical_inputed_visible {
  display: block;
}

.coopbbsButton_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.coopbbsButton_wrap.column {
  flex-direction: column;
  gap: 20px;
}

.coopbbsButton_wrap.row {
  flex-direction: row;
  gap: 10px;
}

.coopbbsButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px 0 20px;
  border-radius: 50px;
  text-align: center;
  font-weight: bold;
}
.coopbbsButton::after {
  position: absolute;
  right: 5%;
  content: "";
  width: 20px;
  height: 20px;
  background: right url("../../../../img/nextArrow-white.svg") no-repeat;
}
.coopbbsButton.size_l {
  width: 310px;
  height: 60px;
  font-size: 18px;
}
.coopbbsButton.color_pink {
  color: #fff;
  background-color: #ffa5c8;
}
.coopbbsButton.color_disable {
  color: #fff;
  background-color: #b9b9b9;
}
