@charset "UTF-8";

.registInputboxWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;
}

.registTextbox_title-wrap {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.registTextbox_title-wrap .requireText {
  margin-left: auto;
  font-size: 14px;
}

.registTextbox_title-wrap .require {
  font-size: 14px;
  color: #8b8b8b;
}

.registTextbox_title-wrap .title,
.registPanel_title .title {
  font-weight: bold;
}

.registTextbox_title-wrap .require,
.registPanel_title .require {
  font-size: 14px;
  font-weight: normal;
}

.registTextbox_title-wrap .require {
  color: #8b8b8b;
}

.tagCloud-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.tagCloud-wrap input[type="checkbox"] {
  display: none;
}
.tagCloud-wrap input[type="checkbox"]:checked + label {
  background: #ffeaf2;
}
.tagCloud-wrap label.checkboxList {
  display: flex;
  height: 38px;
  align-items: center;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  padding: 5px 15px;
  background: #fff;
  cursor: pointer;
}
.tagCloud-wrap label.checkboxList span {
  display: inline-block;
  max-width: 90%;
}

.coopbbsButton_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.coopbbsButton_wrap.column {
  flex-direction: column;
  gap: 20px;
}

.coopbbsButton_wrap.row {
  flex-direction: row;
  gap: 10px;
}

.coopbbsButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px 0 20px;
  border-radius: 50px;
  text-align: center;
  font-weight: bold;
}
.coopbbsButton::after {
  position: absolute;
  right: 5%;
  content: "";
  width: 20px;
  height: 20px;
  background: right url("../../../../img/nextArrow-white.svg") no-repeat;
}
.coopbbsButton.size_l {
  width: 310px;
  height: 60px;
  font-size: 18px;
}

.coopbbsButton.color_pink {
  color: #fff;
  background-color: #ffa5c8;
}
.coopbbsButton.color_disable {
  color: #fff;
  background-color: #b9b9b9;
}
