@charset "UTF-8";

.event_back,
.lifelog_back,
.passport_pack {
  background: #f4f4f4;
}

.content_all {
  width: 100%;
}

.side_padding {
  padding: 0 20px;
}

.coopbbs_intro-slick {
  padding-top: 20px;
}

.coopbbs_intro-wrap {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  gap: 25px;
  width: 93%;
  height: 470px;
  margin-bottom: 10px;
  padding: 30px 20px;
  border-radius: 30px;
  background: #fff;
}

.coopbbs_intro-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 20px;
}

.content_inner,
.content_blkshrink,
.content_blk,
.content_fit,
.content_blkfit {
  max-width: 400px;
  margin: 0 auto;
  position: relative;
}

.coopbbs_block_wrap {
  padding: 30px;
  min-height: 530px;
}

.coopbbsButton_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.coopbbsButton_wrap.column {
  flex-direction: column;
  gap: 20px;
}

.coopbbsButton_wrap.row {
  flex-direction: row;
  gap: 10px;
}

.coopbbsButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px 0 20px;
  border-radius: 50px;
  text-align: center;
  font-weight: bold;
}
.coopbbsButton::after {
  position: absolute;
  right: 5%;
  content: "";
  width: 20px;
  height: 20px;
  background: right url("../../../../img/nextArrow-white.svg") no-repeat;
}
.coopbbsButton.size_l {
  width: 310px;
  height: 60px;
  font-size: 18px;
}

.coopbbsButton.color_pink {
  color: #fff;
  background-color: #ffa5c8;
}

.image_layout {
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
