@charset "UTF-8";

html,
body {
  height: 100%;
}

abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  word-break: break-all;
}

ins {
  text-decoration: none;
}

ins,
mark {
  background-color: #ff9;
  color: #000;
}

mark {
  font-style: italic;
  font-weight: 700;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

html,
body {
  overflow-x: auto;
  -webkit-text-size-adjust: 100%;
}

img {
  max-width: 100%;
  margin: 0;
  padding: 0;
  vertical-align: bottom;
}

ul,
ol {
  list-style-type: none;
}

a,
.a_color {
  text-decoration: none;
  font-weight: normal;
  cursor: pointer;
}

a:hover {
  opacity: 0.8;
}

.a_color {
  display: inline;
}

td {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7,
h8 {
  font-size: 1em;
  font-weight: bold;
}

*,
*:before,
*:after {
  /*border幅を入れてwidth計算*/
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

/* 基本設定： フォント */
@media screen and (max-width: 767px) {
  :root {
    font-size: 100%;
  }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
  :root {
    font-size: 100%;
  }
}
@media screen and (min-width: 769px) {
  :root {
    font-size: 100%;
  }
}

body {
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN",
    "Hiragino Sans", Meiryo, sans-serif;
  color: #000;
  line-height: 1.5;
  background: #f4f4f4;
}
