@charset "UTF-8";

.coopbbs_back {
  background: #f4f4f4;
}

.coopbbs_block_wrap {
  margin: 30px;
}

.introductionTitle {
  font-weight: bold;
}

.requester_nameIcon {
  flex: 0 0 80px;
  -webkit-mask-image: url(../../../../img/mask.svg);
  mask-image: url(../../../../img/mask.svg);
  -webkit-mask-size: contain;
  mask-size: contain;
  height: 80px;
  width: 80px;
}
.requester_nameIcon img {
  height: 80px;
  width: 80px;
}

.requesterIntro {
  padding-top: 15px;
  padding-bottom: 50px;
  border-top: 1px solid #b9b9b9;
}

.boardPanel_nameProfile,
.requester_nameProfile,
.chat_nameProfile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.requester_nameProfile {
  position: relative;
}

.boardPanel_status-numtrue,
.boardPanel_status-numzero,
.boardPanel_actionChat-unread,
.requester-unread {
  display: inline-block;
  margin-left: auto;
  color: #fff;
  font-weight: bold;
  padding: 3px 16px;
  border-radius: 50px;
  text-align: center;
}

.boardPanel_status-numtrue,
.boardPanel_actionChat-unread,
.requester-unread {
  background-color: #fd1744;
}

.requester-unread {
  position: absolute;
  top: 0;
  right: 0;
}

.introductionText {
  margin-top: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-size: 14px;
}

.commentToSupporter {
  display: flex;
  flex-direction: column;
  margin: 10px 0 30px 0;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.boardPanel_accordionDetail-sublabel,
.commentToSupporter-sublabel {
  font-weight: bold;
  color: #ffa5c8;
}

.mg_t_20 {
  margin-top: 20px;
}

.mg_b_10 {
  margin-bottom: 10px;
}

.boardPanel_accordionDetail-sendDate,
.commentToSupporter-sendDate {
  font-size: 14px;
  color: #8b8b8b;
}

.coopbbsButton_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.coopbbsButton_wrap.column {
  flex-direction: column;
  gap: 20px;
}

.coopbbsButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px 0 20px;
  border-radius: 50px;
  text-align: center;
  font-weight: bold;
}

.coopbbsButton.color_pink {
  color: #fff;
  background-color: #ffa5c8;
}

.coopbbsButton::after {
  position: absolute;
  right: 5%;
  content: "";
  width: 20px;
  height: 20px;
  background: right url("../../../../img/nextArrow-white.svg") no-repeat;
}

.coopbbsButton.size_l {
  width: 310px;
  height: 60px;
  font-size: 18px;
}

.coopbbsButton.color_white {
  color: #ffa5c8;
  background-color: #fff;
}
.coopbbsButton.color_white::after {
  background: right url("../../../../img/leftArrow-pink.svg") no-repeat;
  left: 5%;
}

.clickable {
  cursor: pointer;
}
