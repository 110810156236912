@charset "UTF-8";

.content_inner {
  text-align: center;
}

.article_margin {
  width: min(100%, 500px);
  margin: auto;
}

.coopbbs_block_wrap {
  padding: 10px 30px 0 30px;
  height: 100vh;
  background: #f4f4f4 !important;
}

.registInputboxWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;
}

.boardPanel_nameProfile,
.requester_nameProfile,
.chat_nameProfile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.requester_nameProfile {
  position: relative;
}

.requester_nameIcon {
  flex: 0 0 80px;
  /* gfs削除ここから
  -webkit-mask-image: url(../images/mask.svg);
  mask-image: url(../images/mask.svg);
  gfs削除ここまで */
  -webkit-mask-size: contain;
  mask-size: contain;
  height: 80px;
  width: 80px;
}
.requester_nameIcon img {
  height: 80px;
  width: 80px;
}

.coopbbsButton-introduce {
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 30px;
  border: 0;
  border-radius: 5px;
  text-align: center;
  background-color: #87bb57;
  color: #fff;
}
.coopbbsButton-introduce.readonly {
  border: 1px solid #b9b9b9;
  background-color: #fff;
  color: #87bb57;
}

.coopbbsButton-introduce.disabled {
  border: 1px solid #b9b9b9;
  background-color: #fff;
  color: #b9b9b9;
}

.registTextbox textarea,
.introduceTextbox textarea,
.registTextbox .registInputText {
  width: 100%;
  padding: 10px;
  line-height: 1.6;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
}

.introduceTextbox textarea.readonly {
  border: 0;
}

.introduceTextbox textarea {
  height: 400px;
}

.introduceTextbox textarea.readonly {
  border: 0;
}

.gfs_button_not_selected {
  display: none;
}

.gfs_button_right {
  margin: 0 0 0 auto;
}

.gfs_not_inputed_coopbbsButton-introduce {
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 30px;
  border: 0;
  border-radius: 5px;
  text-align: center;
  background-color: gray;
  color: #fff;
}
