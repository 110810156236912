@charset "UTF-8";

.pageTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 16px;
  font-size: 20px;
}

.pageTitle_color-everyones {
  background: #ffd6d6;
  margin-bottom: 30px;
}

.pageTitle .backArrow {
  display: block;
  position: absolute;
  left: 20px;
  top: 10px;
  width: 40px;
}

.boardPanel_outer_container {
  overflow: auto;
  background: #f4f4f4;
}

.boardPanel_outer_container > div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}

.filter {
  display: block !important;
  padding: 0 30px;
  margin-bottom: 20px !important;
}
.filter input[type="checkbox"] {
  appearance: none;
}
.filter label::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid gray;
  border-radius: 4px;
  background: #fff;
}
.filter label::after {
  content: "";
  display: inline-block;
  height: 10px;
  width: 15px;
  border-left: 3px solid;
  border-bottom: 3px solid;
  color: #ff97c3;
  transform: rotate(-45deg);
}
.filter label {
  position: relative;
}
.filter label::before,
.filter label::after {
  position: absolute;
}
/*Outer-box*/
.filter label::before {
  top: 0;
}
/*Checkmark*/
.filter label::after {
  top: 5px;
  left: 4px;
}
/*Hide the checkmark by default*/
.filter input[type="checkbox"] + label::after {
  content: none;
}
/*Unhide the checkmark on the checked state*/
.filter input[type="checkbox"]:checked + label::after {
  content: "";
}

.filter > hr {
  margin: 15px -15px;
}
