@charset "UTF-8";

.registFaq_title {
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  white-space: normal;
}

.registFaq_text {
  margin: 20px 5px 30px 5px;
  white-space: normal;
}
