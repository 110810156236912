@charset "UTF-8";

.content_inner {
  max-width: 400px;
  margin: 0 auto;
  position: relative;
  background: #f4f4f4;
}

.pageTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 16px;
  font-size: 20px;
}

.pageTitle_color-list {
  background: #fde4ce;
}

.pageTitle_color-everyones {
  background: #ffd6d6;
}

.pageTitle_color-myrequest {
  background: #f0d2f7;
}

.pageTitle_color-regist {
  background: #ffeaf2;
}

.chatHeader .backArrow,
.pageTitle .backArrow {
  display: block;
  position: absolute;
  left: 20px;
  top: 10px;
  width: 40px;
}

.chatHeader .backArrow {
  top: 20px;
}

.coopbbs_block_wrap {
  padding: 30px;
  min-height: 530px;
}

.tutorial_anchorButton-wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tutorial_anchorButton {
  position: relative;
  display: flex;
  padding: 14px 20px;
  align-items: center;
  color: inherit;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
}
.tutorial_anchorButton::after {
  position: absolute;
  right: 20px;
  content: "";
  width: 20px;
  height: 20px;
  /* background: right url("../../images/accrodionArrow.svg") no-repeat; */
}

.tutorial_section {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.tutorialBlock-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
}
.tutorialBlock-wrap .tutorialBlock_sectionImage {
  margin: 40px auto 20px auto;
}
.tutorialBlock-wrap .tutorialBlock_title {
  font-size: 18px;
  font-weight: bold;
}
.tutorialBlock-wrap.support .tutorialBlock_title {
  color: #fcb16f;
}
.tutorialBlock-wrap.request .tutorialBlock_title {
  color: #e4abf2;
}
.tutorialBlock {
  padding: 20px;
}
.boardPanel_title.close {
  background: #d9e2e6;
}

.boardPanel_title.list {
  background: #fde4ce;
}

.boardPanel_title.everyones {
  background: #ffd6d6;
}

.boardPanel_title.myrequest {
  background: #f0d2f7;
}

.boardPanel_title.tutorial {
  margin: 10px 0;
  padding: 10px 30px 10px 50px;
  border-radius: 5px;
  background: #ffeaf2;
  font-weight: normal;
  color: inherit;
}
.boardPanel_title.tutorial::before {
  position: absolute;
  content: "";
  width: 30px;
  height: 30px;
  left: 10px;
  /* background: center url("../../images/icon_tutorial.svg") no-repeat; */
}

.boardPanel_title.icon_regist.tutorial::before {
  /* background-image: url("../../images/icon_tutorial_regist.svg"); */
}

.boardPanel_title.icon_registEx.tutorial::before {
  /* background-image: url("../../images/icon_tutorial_registEx.svg"); */
}

.boardPanel_title.icon_supporter.tutorial::before {
  /* background-image: url("../../images/icon_tutorial_supporter.svg"); */
}
.boardPanel_title,
.registPanel_title {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.boardPanel_title {
  padding: 15px;
}

.clickable {
  cursor: pointer;
}

.clickable::after {
  font-family: "Font Awesome 5 Free";
  font-size: 24px;
  font-weight: bold;
  content: "\f105";
  position: absolute;
  right: 10px;
}

hr.boardPanel_divider,
hr.detailPanel_hr {
  margin: 0;
  border-top: 1px solid #b9b9b9;
}

hr.divider {
  width: 100%;
  margin: 0;
  border-top: 1px solid #b9b9b9;
}
hr.divider.dashed {
  border-style: dashed;
}

hr.boardPanel_accordion-divider {
  margin: 0;
  border-top: 1px dashed #b9b9b9;
}

.tutorialImageList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4%;
  margin-top: 20px;
}
.tutorialImageList .tutorialImage {
  flex: 1;
}
.tutorialImageList .tutorialImage img {
  max-width: 33vw;
}

/* gfs追加ここから */
.gfs_tutorial_text {
  text-align: left;
}

.gfs_tutorial_click_icon {
  position: absolute;
  right: 20px;
}
/* gfs追加ここまで */
