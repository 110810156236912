@charset "UTF-8";

.registTextbox_title-wrap {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.registTextbox_title-wrap .requireText {
  margin-left: auto;
  font-size: 14px;
}

.registTextbox_title-wrap .require {
  font-size: 14px;
  color: #8b8b8b;
}

.registTextbox_title-wrap .title,
.registPanel_title .title {
  font-weight: bold;
}

.registTextbox_title-wrap .require,
.registPanel_title .require {
  font-size: 14px;
  font-weight: normal;
}

.registTextbox_title-wrap .require {
  color: #8b8b8b;
}

.registTextbox textarea,
.introduceTextbox textarea,
.registTextbox .registInputText {
  width: 100%;
  padding: 10px;
  line-height: 1.6;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
}
