@charset "UTF-8";

.boardPanel_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 30px 30px 30px;
}

.boardPanel_wrap {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
  overflow: hidden;
}

.boardPanel_title.list {
  background: #fde4ce;
}

.clickable {
  cursor: pointer;
}

.boardPanel_title {
  padding: 15px;
}

.boardPanel_title,
.registPanel_title {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.boardPanel_2column {
  display: flex;
}

.boardPanel_date-label {
  width: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px dashed #b9b9b9;
}

.boardPanel_date {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
}

hr.boardPanel_divider {
  margin: 0;
  border-top: 1px solid #b9b9b9;
}

.boardPanel_item,
.registPanel_item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
}

.boardPanel_status-msg {
  position: relative;
  padding-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boardPanel_status-msg::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  background: center url("../../../../img/icon_status_msg_smile.svg") no-repeat;
  left: 0px;
}

.boardPanel_status-numtrue,
.boardPanel_actionChat-unread,
.requester-unread {
  background-color: #fd1744;
}

.boardPanel_status-numtrue,
.boardPanel_status-numzero,
.boardPanel_actionChat-unread,
.requester-unread {
  display: inline-block;
  min-width: 60px;
  margin-left: auto;
  color: #fff;
  font-weight: bold;
  padding: 3px 16px;
  border-radius: 50px;
  text-align: center;
}

.boardPanel_status-numzero {
  color: #fd1744;
  background-color: #fff;
  border: 1px solid #fd1744;
}

.boardPanel_item,
.registPanel_item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
}

.boardPanel_action-cancel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding-left: 20px;
  color: #8b8b8b;
}
.boardPanel_action-cancel::before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  /* background: center url("../../../images/icon_action_cancel.svg") no-repeat; */
  left: 0;
}

.boardPanel_title.list {
  background: #fde4ce;
}

/* GFS追加　- ここから　- */
.gfs_arrow_position {
  margin-left: auto;
}
/* GFS追加　- ここまで- */

.boardPanel_2column {
  display: flex;
}

.boardPanel_date-label {
  width: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px dashed #b9b9b9;
}

.boardPanel_date {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
}

.boardPanel_status {
  display: flex;
  width: 100%;
}

.boardPanel_action-cancel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding-left: 20px;
  color: #8b8b8b;
}
.boardPanel_action-cancel::before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  /* background: center url("../../../images/icon_action_cancel.svg") no-repeat; */
  left: 0;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  word-break: break-all;
}

a,
.a_color {
  text-decoration: none;
  font-weight: normal;
  cursor: pointer;
}

.text_12 {
  font-size: 75%;
}
