@charset "UTF-8";

.coopbbs_back {
  background: #f4f4f4;
}

.button_action {
  border-radius: 10px;
  background: #ffffff;
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 15px 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  /* margin-bottom: 20px; */
}

.button_action_label img {
  width: 24px;
  margin-right: 5px;
  display: inline-block;
}

.boardPanel_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 30px 30px 30px;
}

.boardPanel_status-numtrue,
.boardPanel_actionChat-unread,
.requester-unread {
  background-color: #fd1744;
}

.boardPanel_status-numtrue,
.boardPanel_status-numzero,
.boardPanel_actionChat-unread,
.requester-unread {
  display: inline-block;
  min-width: 60px;
  margin-left: auto;
  color: #fff;
  font-weight: bold;
  padding: 3px 16px;
  border-radius: 50px;
  text-align: center;
}

.boardPanel_status-numzero {
  color: #fd1744;
  background-color: #fff;
  border: 1px solid #fd1744;
}

.detailPanel_wrap {
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
}

.text_12 {
  font-size: 75%;
}

.boardPanel_label-s,
.registPanel_label-s,
.time_stamp {
  display: block;
  font-size: 14px;
  color: #8b8b8b;
}

/* GFS追加 -----ここから---- */
.faAngleRight {
  position: absolute;
  right: 5px;
  color: #f5a7d1;
  display: inline-block;
  font-weight: bolder;
  font-size: 19px;
}
.clickable {
  cursor: pointer;
}
/* GFS追加 -----ここまで---- */
