@charset "UTF-8";

.content_all {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.header {
  background-color: #f4f4f4;
  padding: 0 15px 15px 15px;
}

.chat_body {
  background-color: #fff;
  overflow-y: auto;
}

.footer {
  /* background-color: cornflowerblue; */
}

.chatHeader {
  display: flex;
  flex-direction: column;
  background: #f4f4f4;
}

.backArrow {
  position: relative;
  top: 15px;
  left: 15px;
}

.chat_nameProfile {
  display: flex;
  align-self: center;
  gap: 10px;
}

.gfs_div_center {
  display: flex;
  align-self: center;
}

.coopbbsButton_wrap {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coopbbsButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px 0 20px;
  border-radius: 50px;
  text-align: center;
  font-weight: bold;
}
.coopbbsButton::after {
  position: absolute;
  right: 5%;
  content: "";
  width: 20px;
  height: 20px;
  background: right url("../../../../img/nextArrow-white.svg") no-repeat;
}

.coopbbsButton.size_m {
  height: 40px;
}

.coopbbsButton.color_pink {
  color: #fff;
  background-color: #ffa5c8;
}

.chatInput-wrap {
  display: flex;
  align-items: center;
  background: #f4f4f4;
  padding: 7px 15px;
  border-top: 1px solid #e3e3e3;
  gap: 15px;
}

.chatInput {
  width: 100%;
  height: 36px;
  padding: 8px 10px;
  border-radius: 10px;
  border: 1px solid #b9b9b9;
  background-color: #fff;
  transition: height 0.5s;
  overflow: hidden;
  resize: none;
}

.chatInput_send {
  display: flex;
  width: 36px;
  height: 36px;
}

.chatArea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  padding: 0 15px 15px 15px;
  height: max;
  background-color: #fff;
  width: 100%;
}

.chatDate {
  padding: 5px 20px;
  border-radius: 50px;
  font-size: 12px;
  text-align: center;
  background-color: #f4f4f4;
  color: #b8b8b8;
  margin: 0 auto;
  width: 170px;
}

.chatTextbox-wrap {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  width: 100%;
}

.chatText-your .chatTextbox {
  position: relative;
  background: #e1f5ce;
}
.chatText-your .chatTextbox::after {
  position: absolute;
  content: "";
  right: -10px;
  bottom: 0;
  width: 20px;
  height: 10px;
  /* background: right url("../../images/chatBaloon_self.svg") no-repeat; */
}

.chatTextbox-wrap.chatText-your {
  flex-direction: row-reverse;
}

.chatTextbox {
  max-width: 250px;
  padding: 15px;
  border-radius: 10px;
  font-size: 14px;
}

.chatTextbox-wrap.chatText-self {
  margin-bottom: 10px;
}

.chatText-self .chatTextbox {
  position: relative;
  background: #eceff1;
}
.chatText-self .chatTextbox::after {
  position: absolute;
  content: "";
  left: -10px;
  bottom: 0;
  width: 20px;
  height: 10px;
  /* background: right url("../../images/chatBaloon_your.svg") no-repeat; */
}

.chatSendInfo-time {
  font-size: 12px;
}

.gfs_set_bottom {
  margin-bottom: 0;
}

.err_msg {
  padding: 0 10px;
  background-color: #d9d9d9;
}
