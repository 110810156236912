@charset "UTF-8";

/* チェックボックスリスト */
.checkboxList-wrap {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid #b9b9b9;
}
.checkboxList-wrap input[type="checkbox"] {
  display: none;
}
.checkboxList-wrap input[type="checkbox"]:checked + label {
  background: #ffd9ee;
}
.checkboxList-wrap input[type="checkbox"]:checked + label::after {
  background: center url("../../../../img/checkbox_on.svg") no-repeat;
}
.checkboxList-wrap label.checkboxList {
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
  padding: 15px 15px 15px 10px;
  cursor: pointer;
}
.checkboxList-wrap label.checkboxList span {
  display: inline-block;
  max-width: 90%;
}
.checkboxList-wrap label.checkboxList::after {
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  margin-left: auto;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  background: #fff;
}
.checkboxList-wrap label:not(:first-of-type) {
  border-top: 1px solid #b9b9b9;
}
