@charset "UTF-8";

.registInputboxWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;
}

.registTextbox_title-wrap {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.registTextbox_title-wrap .requireText {
  margin-left: auto;
  font-size: 14px;
}

.registTextbox_title-wrap .require {
  font-size: 14px;
  color: #8b8b8b;
}

.registTextbox_title-wrap .title {
  font-weight: bold;
}

.radioButton_flex-wrap {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.radioButton_flex-wrap input[type="radio"] {
  display: none;
}
.radioButton_flex-wrap input[type="radio"]:checked + label {
  background: #ffd9ee;
}
.radioButton_flex-wrap label.radioButton {
  flex: 1;
  padding: 12px;
  background: #fff;
  text-align: center;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  cursor: pointer;
}

.registInput_kanaColumn-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 20px;
  margin-bottom: 20px;
}

.registInput_kanaColumn {
  text-decoration: underline;
  font-size: 18px;
  cursor: pointer;
}
.registInput_kanaColumn.current {
  font-weight: bold;
  text-decoration: none;
  cursor: inherit;
}

/* チェックボックスリスト */
.checkboxList-wrap {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid #b9b9b9;
}
.checkboxList-wrap input[type="checkbox"] {
  display: none;
}
.checkboxList-wrap input[type="checkbox"]:checked + label {
  background: #ffd9ee;
}
.checkboxList-wrap input[type="checkbox"]:checked + label::after {
  /* background: center url("../../images/checkbox_on.svg") no-repeat; */
}
.checkboxList-wrap label.checkboxList {
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
  padding: 15px 15px 15px 10px;
  cursor: pointer;
}
.checkboxList-wrap label.checkboxList span {
  display: inline-block;
  max-width: 90%;
}
/* .checkboxList-wrap label.checkboxList::after {
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  margin-left: auto;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  background: #fff;
} */
.checkboxList-wrap label:not(:first-of-type) {
  border-top: 1px solid #b9b9b9;
}

.tagCloud-wrap label.checkboxList {
  display: flex;
  height: 38px;
  align-items: center;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  padding: 5px 15px;
  background: #fff;
  cursor: pointer;
}
.tagCloud-wrap label.checkboxList span {
  display: inline-block;
  max-width: 90%;
}

.coopbbsButton_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.coopbbsButton_wrap.column {
  flex-direction: column;
  gap: 20px;
}

.coopbbsButton_wrap.row {
  flex-direction: row;
  gap: 10px;
}

.coopbbsButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px 0 20px;
  border-radius: 50px;
  text-align: center;
  font-weight: bold;
}
.coopbbsButton::after {
  position: absolute;
  right: 5%;
  content: "";
  width: 20px;
  height: 20px;
  background: right url("../../../../img/nextArrow-white.svg") no-repeat;
}
.coopbbsButton.size_l {
  width: 310px;
  height: 60px;
  font-size: 18px;
}
.coopbbsButton.size_m {
  height: 40px;
}
.coopbbsButton.color_green {
  color: #fff;
  background-color: #87bb57;
}
.coopbbsButton.color_blue {
  color: #fff;
  background-color: #4792e0;
}
.coopbbsButton.color_pink {
  color: #fff;
  background-color: #ffa5c8;
}
.coopbbsButton.color_disable {
  color: #fff;
  background-color: #b9b9b9;
}
.coopbbsButton.color_white {
  color: #ffa5c8;
  background-color: #fff;
}
.coopbbsButton::after.color_white {
  /* background: right url("../../images/leftArrow-pink.svg") no-repeat; */
  left: 5%;
}

/* gfs追加ここから */
.gfs_radioButton_checked {
  background-color: #ffd9ee !important;
}

#gfs_radioButton {
  background-color: #fff;
}

.gfs_town_name_not_select {
  display: none;
}

.gfs_town_name_select {
  display: block;
}

.gfs_town_name_show {
  text-align: left;
}

.gfs_town_name_not_checked_box {
  margin-right: 4px;
  margin-top: 4px;
  margin-left: auto;
  /* width: 14px;
  height: 14px;
  border: solid 2px #333; */
  width: 18px;
  height: 16px;
  margin-left: auto;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  background: #fff;
}

.gfs_town_name_checked_box {
  /* margin-right: 0;
  margin-left: auto; */
  margin-right: 4px;
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 18px;
  height: 18px; */
  margin-left: auto;
  /* border: 1px solid #b9b9b9;
  border-radius: 4px;
  background: #fff; */
}

.gfs_error_messages_invisible {
  display: none;
}
.gfs_error_messages_visible {
  display: block;
}

.gfs_textarea_sentence_errored {
  width: 314px;
  height: 206px;
  border: 3px solid red;
  border-radius: 9px;
  margin: auto;
  display: block;
}

.gfs_error_messages_freeword {
  text-align: left;
  color: red;
  font-size: 16px;
}

.coopbbsButton.gfs_color_gray {
  color: #fff;
  background-color: #d3d3d3;
}

/* gfs追加ここまで */
