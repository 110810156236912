@charset "UTF-8";

.title_wrap {
  margin-bottom: 30px;
}

.pageTitle_color-list {
  background: #fde4ce;
}

.pageTitle_color-myrequest {
  background: #f0d2f7;
}
.pageTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 16px;
  font-size: 20px;
}

.pageTitle .backArrow {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 6px;
  height: 100%;
  width: 40px;
}

.clickable {
  cursor: pointer;
}

.headerTab ul {
  display: flex;
  height: 52px;
  background: #fff;
  color: #8b8b8b;
  border-bottom: 1px solid #e8e8e8;
}

.headerTab_menuIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: pointer;
}
.headerTab_menuIcon img {
  height: 25px;
  display: inline-block;
  margin-left: 10px;
}

.headerTab_menuIcon.active {
  color: #000;
  background: rgb(255, 165, 200);
  background: linear-gradient(
    180deg,
    rgb(255, 165, 200) 5px,
    rgba(255, 165, 200, 0) 5%
  );
}
