@charset "UTF-8";

.content_all {
  width: 100%;
}

.lifelog_back {
  background: #f4f4f4;
}

.content_inner,
.content_blkshrink,
.content_blk,
.content_fit,
.content_blkfit {
  max-width: 400px;
  margin: 0 auto;
  position: relative;
}

.lifelog_block_wrap {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  /* gap: 50px; */
}
.lifelog_block_wrap > div > div {
  margin-bottom: 50px;
}

.lifelog_block {
  padding: 0 20px;
  text-align: left;
}

.lifelog_date {
  border-radius: 20px;
  background: #ffffff;
  color: #87bb57;
  font-weight: bold;
  font-size: 14px;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.lifelog_item dl dt {
  font-weight: bold;
}

.lifelog_item {
  display: flex;
  font-size: 14px;
  padding: 0 12px;
}

.lifelog_item_hr {
  border-top: 1px solid #d1d1d1;
  margin: 10px 0;
  opacity: 1;
}

.log_tag {
  display: inline-block;
  font-size: 80%;
  margin: 0 5px 5px 0;
  padding: 2px 11px 0 11px;
  color: #ffffff;
  font-weight: bold;
  border-radius: 5px;
}

.tag_bg_eye {
  background: #f06f6f;
}
.tag_bg_event {
  background: #87bb57;
}
.tag_bg_pay {
  background: #4b98c7;
}
.tag_bg_pass {
  background: #b8956c;
}

.top_explain {
  padding: 0 30px 20px 30px;
  text-align: left;
  font-weight: bold;
}
