.modalArea {
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  position: fixed;
}

.modalWrapper {
  /* width: 70%; */
  width: 80%; /* GFS 修正*/
  max-width: 350px;
  padding: 25px 20px;
  background-color: #eceff1;
  border-radius: 10px;
}

.modalContents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.dialog_title {
  text-align: left;
}

.dialog_message {
  margin-top: 10px;
  text-align: left;
}

.modalButtons {
  display: flex;
  justify-content: center;
}

.dialog_button {
  width: 150px;
  margin-top: 30px;
  border: 1px solid #b9b9b9;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  gap: 1px;
  color: #4792e0;
  font-size: 16px;
  padding: 10px;
}

.dialog_button spacer {
  width: 30px;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  border: 0;
  cursor: pointer;
  -webkit-appearance: button;
}
