@charset "UTF-8";

.detailPanel_wrap {
  background: #ffffff;
  border-radius: 10px;
  /* margin-bottom: 20px; */
}

.detailPanel_title.my_help {
  background-color: #fde4ce;
}

.detailPanel_title.myrequest {
  background: #f0d2f7;
}

.detailPanel_title {
  font-weight: bold;
  padding: 15px 10px;
  border-radius: 10px 10px 0 0;
}

.detailPanel_block {
  padding: 20px;
}

hr.boardPanel_divider,
hr.detailPanel_hr {
  margin: 0;
  border-top: 1px solid #b9b9b9;
}

.my_help .detailPanel_item {
  color: #ffa5c8;
}

.detailPanel_item {
  font-weight: bold;
  margin-bottom: 10px;
}

.detailPanel_content span {
  color: #8b8b8b;
  margin-top: 5px;
  display: inline-block;
}
