@charset "UTF-8";

.registPanels_flex {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}

.coopbbsButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  text-align: center;
  font-weight: bold;
}
.coopbbsButton::after {
  position: absolute;
  right: 5%;
  content: "";
  width: 20px;
  height: 20px;
  /* GFS削除　- ここから　- */
  /* background: right url("../../images/nextArrow-white.svg") no-repeat; */
  /* GFS削除　- ここから　- */
}

.coopbbsButton.size_l {
  width: 310px;
  height: 60px;
  font-size: 18px;
}

.coopbbsButton.color_pink {
  color: #fff;
  background-color: #ffa5c8;
}

.coopbbsButton.gfs_color_gray {
  color: #fff;
  background-color: #b9b9b9;
}

.coopbbsButton_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.coopbbsButton_wrap.column {
  gap: 20px;
  /* width: 80%; */
  /* GFS追加　- ここから　- */
  margin: 20px auto;
  border: none;
  background: transparent;
  /* GFS追加　- ここまで　- */
}

.coopbbsButton_wrap.row {
  flex-direction: row;
  gap: 10px;
}
