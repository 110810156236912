.content_all {
  width: 100%;
}

.event_back {
  background: #f4f4f4;
}

.coopbbs_block_wrap {
  padding: 30px;
  min-height: 530px;
}

.introMebukueye_message_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 30px 20px;
  margin-bottom: 80px;
  border-radius: 5px;
  background-color: #fff;
}

.text_14 {
  font-size: 87.5%;
}
@media screen and (min-width: 768px), print {
  .text_14 {
    font-size: 87.5%;
  }
}

.introMebukueye_message_wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.introMebukueye_message {
  padding: 10px;
  background-color: #fff5eb;
  border-radius: 10px;
}
.introMebukueye_message span.place {
  font-weight: bold;
}

.downloadMebukuApp_fix {
  position: fixed;
  bottom: 10px;
  width: 100%;
  padding: 10px;
}

.flex_c {
  justify-content: center;
  align-items: center;
}

.downloadMebukuApp {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9bce6c;
  border-radius: 5px;
  gap: 10px;
  padding: 10px;
}
/* ↓ 20231204_GFS追加 ↓ */
.downloadMebukuApp:hover {
  opacity: 0.8;
}

.text_16 {
  font-size: 100%;
}
@media screen and (min-width: 768px), print {
  .text_16 {
    font-size: 100%;
  }
}

.line_h_ss {
  line-height: 1.3;
}

article p:not(:last-child),
.article_round p:not(:last-child),
.article_round_notab p:not(:last-child) {
  margin-bottom: 1em;
}

/* ↓ 20231221_GFS追加 ↓ */
.pageTitle {
  width:100%;
}

.pageTitle_color-everyones {
  background-color: rgba(0, 0, 0, 0);
}

.pageTitle .backArrow {
  display: block;
}

.backArrow img {
  display: block;
}