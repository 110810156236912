@charset "UTF-8";

.gfs_card_title {
  background-color: #ffa5c8;
  border-radius: 15px;
}

.registPanel_title .require {
  font-size: 14px;
  font-weight: normal;
  color: #fff;
}

.registPanel_title.regist {
  background: #ffa5c8;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
}

.gfs_right_pointer {
  float: right;
  height: 25px;
  margin-top: -2px;
}

.gfs_inputedText {
  text-align: left;
}

.gfs_title_button {
  /* width: 83%; */
  height: 45px;
  border-radius: 5px;
  /* margin: 1em auto; */
  padding: 0.6em 0.9em;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #ffa5c8;
}
