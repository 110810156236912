@charset "UTF-8";

.content_all {
  width: 100%;
}

.event_back,
.lifelog_back,
.passport_pack {
  background: #f4f4f4;
}

.content_inner,
.content_blkshrink,
.content_blk,
.content_fit,
.content_blkfit {
  max-width: 400px;
  margin: 0 auto;
  position: relative;
}

.pageTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 16px;
  font-size: 20px;
}

.chatHeader .backArrow,
.pageTitle .backArrow {
  display: block;
  position: absolute;
  left: 20px;
  top: 10px;
  width: 40px;
}

.pageTitle_color-regist {
  background: #ffeaf2;
}

.chatHeader .backArrow,
.pageTitle .backArrow {
  display: block;
  position: absolute;
  left: 20px;
  top: 10px;
  width: 40px;
}

.chatHeader .backArrow {
  top: 20px;
}

.registInputboxWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;
}

.registRoutenaviMap_input-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}

.registRoutenaviMap_input {
  width: 100%;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  padding: 5px 5px 5px 45px;
  background: #fff;
  position: relative;
  align-self: flex-end;
}
.registRoutenaviMap_input input {
  border: 0;
  width: 100%;
  font-size: 15px;
}
.registRoutenaviMap_input::before {
  position: absolute;
  font-weight: bold;
  top: 6px;
  left: 5px;
}
.registRoutenaviMap_input.departure::before {
  content: "出発";
}
.registRoutenaviMap_input.arrival::before {
  content: "到着";
}
.registRoutenaviMap_input.via {
  width: 78%;
}
.registRoutenaviMap_input.via::before {
  content: "経由";
}

.registTextbox_title-wrap {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.registTextbox_title-wrap .requireText {
  margin-left: auto;
  font-size: 14px;
}

.registTextbox_title-wrap .require {
  font-size: 14px;
  color: #8b8b8b;
}

.registTextbox_title-wrap .title,
.registPanel_title .title {
  font-weight: bold;
}

.registTextbox_title-wrap .require,
.registPanel_title .require {
  font-size: 14px;
  font-weight: normal;
}

.registTextbox_title-wrap .require {
  color: #8b8b8b;
}

.registRoutenaviMap {
  width: 100%;
  height: 330px;
  border: 1px solid #b9b9b9;
}

.mg_t_50 {
  margin-top: 50px;
}

.coopbbsButton_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.coopbbsButton_wrap.column {
  flex-direction: column;
  gap: 20px;
}

.coopbbsButton_wrap.row {
  flex-direction: row;
  gap: 10px;
}

.coopbbsButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px 0 20px;
  border-radius: 50px;
  text-align: center;
  font-weight: bold;
}
.coopbbsButton::after {
  position: absolute;
  right: 5%;
  content: "";
  width: 20px;
  height: 20px;
  background: right url("../../../../img/nextArrow-white.svg") no-repeat;
}
.coopbbsButton.size_l {
  width: 310px;
  height: 60px;
  font-size: 18px;
}
.coopbbsButton.size_m {
  height: 40px;
}
.coopbbsButton.color_green {
  color: #fff;
  background-color: #87bb57;
}
.coopbbsButton.color_blue {
  color: #fff;
  background-color: #4792e0;
}
.coopbbsButton.color_pink {
  color: #fff;
  background-color: #ffa5c8;
}
.coopbbsButton.color_disable {
  color: #fff;
  background-color: #b9b9b9;
}
.coopbbsButton.color_white {
  color: #ffa5c8;
  background-color: #fff;
}
.coopbbsButton::after.color_white {
  background: right url("../../../../img/leftArrow-pink.svg") no-repeat;
  left: 5%;
}

.searchResult {
  position: absolute;
  top: 200px;
  left: 0px;
  width: 100%;
}
