@charset "UTF-8";

.event_back,
.lifelog_back,
.passport_pack {
  background: #f4f4f4;
}

.content_all {
  width: 100%;
}

.content_inner,
.content_blkshrink,
.content_blk,
.content_fit,
.content_blkfit {
  max-width: 400px;
  margin: 0 auto;
  position: relative;
}

.pageTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 16px;
  font-size: 20px;
}

.pageTitle_color-everyones {
  background: #ffd6d6;
}

.chatHeader .backArrow,
.pageTitle .backArrow {
  display: block;
  position: absolute;
  left: 20px;
  top: 10px;
  width: 40px;
}

.coopbbs_block_wrap {
  padding: 30px;
  min-height: 530px;
}

.introductionTitle {
  font-weight: bold;
}

.requesterIntro {
  padding-top: 15px;
  padding-bottom: 50px;
  border-top: 1px solid #b9b9b9;
}

.boardPanel_nameProfile,
.requester_nameProfile,
.chat_nameProfile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.requester_nameProfile {
  position: relative;
}

.requester_nameIcon {
  flex: 0 0 80px;
  -webkit-mask-image: url(../../../../img/mask.svg);
  mask-image: url(../../../../img/mask.svg);
  -webkit-mask-size: contain;
  mask-size: contain;
  height: 80px;
  width: 80px;
}
.requester_nameIcon img {
  height: 80px;
  width: 80px;
}

.introductionText {
  margin-top: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-size: 14px;
}

.introductionTitle_help {
  font-weight: bold;
  border-bottom: 1px solid #b9b9b9;
  margin-bottom: 20px;
}

.detailPanel_wrap {
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
}

.detailPanel_title {
  font-weight: bold;
  padding: 15px 10px;
  border-radius: 10px 10px 0 0;
}

.help .detailPanel_title {
  background-color: #ffd6d6;
}

.detailPanel_block {
  padding: 20px;
}

.detailPanel_item {
  font-weight: bold;
  margin-bottom: 10px;
}

.help .detailPanel_item {
  color: #f5a7a7;
}

hr.boardPanel_divider,
hr.detailPanel_hr {
  margin: 0;
  border-top: 1px solid #b9b9b9;
}

.detailPanel_content span {
  color: #8b8b8b;
  margin-top: 5px;
  display: inline-block;
}

.time_stamp {
  margin-top: 20px;
  margin-bottom: 60px;
}

.boardPanel_label-s,
.registPanel_label-s,
.time_stamp {
  display: block;
  font-size: 14px;
  color: #8b8b8b;
}

.registTextbox_title-wrap {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.registTextbox_title-wrap .requireText {
  margin-left: auto;
  font-size: 14px;
}

.registTextbox textarea,
.introduceTextbox textarea,
.registTextbox .registInputText {
  width: 100%;
  padding: 10px;
  line-height: 1.6;
  /* border: 1px solid #b9b9b9; */
  border-radius: 5px;
}

.mg_t_30 {
  margin-top: 30px;
}

.coopbbsButton_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.coopbbsButton_wrap.column {
  flex-direction: column;
  gap: 20px;
}

.coopbbsButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px 0 20px;
  border-radius: 50px;
  text-align: center;
  font-weight: bold;
}
/* .coopbbsButton::after {
  position: absolute;
  right: 5%;
  content: "";
  width: 20px;
  height: 20px;
  background: right url("../../images/nextArrow-white.svg") no-repeat;
} */

/* GFS追加 ----ここから---- */
.coopbbsButton_arrow_right {
  position: absolute;
  right: 5%;
  content: "";
  width: 20px;
  height: 20px;
}

.coopbbsButton_arrow_left {
  position: absolute;
  left: 5%;
  content: "";
  width: 20px;
  height: 20px;
}

.borderWarning {
  border: 2px solid #ea3323;
}

.borderNormal {
  border: 1px solid #b9b9b9;
}

/* GFS追加 ----ここまで---- */

.coopbbsButton.size_l {
  width: 310px;
  height: 60px;
  font-size: 18px;
}
.coopbbsButton.color_pink {
  color: #fff;
  background-color: #ffa5c8;
}

.coopbbsButton.color_white {
  color: #ffa5c8;
  background-color: #fff;
}
