@charset "UTF-8";

.event_back,
.lifelog_back,
.passport_pack {
  background: #f4f4f4;
}

.pageTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 16px;
  font-size: 20px;
}

.pageTitle_color-list {
  background: #fde4ce;
}

.pageTitle_color-everyones {
  background: #ffd6d6;
}

.pageTitle_color-myrequest {
  background: #f0d2f7;
}

.pageTitle_color-regist {
  background: #ffeaf2;
}

.pageTitle .backArrow {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 6px;
  height: 30%;
  width: 40px;
}

.headerTab ul {
  display: flex;
  height: 52px;
  background: #fff;
  color: #8b8b8b;
  border-bottom: 1px solid #e8e8e8;
}

.headerTab_menuIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: pointer;
}
.headerTab_menuIcon img {
  height: 25px;
  display: inline-block;
  margin-left: 10px;
}
.headerTab_menuIcon .changeBlack {
  color: #000;
  height: 25px;
  display: inline-block;
  margin-left: 10px;
}

.headerTab_menuIcon.active {
  color: #000;
  background: rgb(255, 165, 200);
  background: linear-gradient(
    180deg,
    rgb(255, 165, 200) 5px,
    rgba(255, 165, 200, 0) 5%
  );
}

.coopbbs_block_wrap {
  margin: 30px;
}

.coopbbs_block_wrap.coopRegist {
  display: flex;
  flex-direction: column;
  min-height: 530px;
}

.registPanels_flex {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;
}

.registTextbox textarea,
.registTextbox .registInputText {
  width: 100%;
  padding: 10px;
  line-height: 1.6;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
}

.registTextbox_title-wrap {
  display: flex;
  align-items: flex-end;
}

.registTextbox_title-wrap .requireText {
  margin-left: auto;
  font-size: 14px;
}

.registTextbox_title-wrap .require {
  font-size: 14px;
  color: #8b8b8b;
}

.registTextbox_title-wrap .title,
.registPanel_title .title {
  font-weight: bold;
}

.registPanel_title .require {
  font-size: 14px;
  font-weight: normal;
  color: #fff;
}

.registPanel_wrap {
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #b9b9b9;
  border-radius: 5px 5px 10px 10px;
  overflow: hidden;
}

.registPanel_title.regist {
  background: #ffa5c8;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
}

.clickable {
  cursor: pointer;
}

.clickable::after {
  font-family: "Font Awesome 5 Free";
  font-size: 24px;
  content: "\f105";
  position: absolute;
  right: 10px;
}

.boardPanel_item,
.registPanel_item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
}

.registPanel_item {
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.boardPanel_label-s,
.registPanel_label-s {
  display: block;
  font-size: 14px;
  color: #8b8b8b;
}

hr.boardPanel_divider {
  margin: 0;
  border-top: 1px solid #b9b9b9;
}

hr.divider {
  margin: 0;
  border-top: 1px solid #b9b9b9;
}

hr.boardPanel_accordion-divider {
  margin: 0;
  border-top: 1px dashed #b9b9b9;
}

.coopbbsButton_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.coopbbsButton_wrap.column {
  gap: 20px;
  width: 80%;
  /* GFS追加　- ここから　- */
  margin: 20px auto;
  border: none;
  background: transparent;
  /* GFS追加　- ここまで　- */
}

.coopbbsButton_wrap.row {
  flex-direction: row;
  gap: 10px;
}

.coopbbsButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  text-align: center;
  font-weight: bold;
}
.coopbbsButton::after {
  position: absolute;
  right: 5%;
  content: "";
  width: 20px;
  height: 20px;
  /* GFS削除　- ここから　- */
  /* background: right url("../../images/nextArrow-white.svg") no-repeat; */
  /* GFS削除　- ここから　- */
}

.coopbbsButton.size_l {
  width: 310px;
  height: 60px;
  font-size: 18px;
}

.coopbbsButton.color_pink {
  color: #fff;
  background-color: #ffa5c8;
}

.registPanels_swipe-wrap {
  margin-bottom: 30px;
}

.registPanels_swipe {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
  line-height: 1;
}
.registPanels_swipe div {
  display: inline-block;
  width: 20px;
  margin: 0 15px;
}

/* オリジナルここまで */

/* GFS追加　- ここから　- */
.gfs_assistcard {
  margin: 1rem auto;
}

.gfs_card_title {
  background-color: #ffa5c8;
  border-radius: 15px;
}

.gfs_title_button {
  width: 83%;
  height: 45px;
  border-radius: 5px;
  margin: 1em auto;
  padding: 0.6em 0.9em;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #ffa5c8;
}

.gfs_content_inner {
  margin: auto;
}

.gfs_card_place {
  margin: auto;
  width: 80%;
}

.gfs_textarea_title {
  width: 100%;
  height: 47px;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  margin: auto;
  display: block;
  padding: 5px 10px;
  font-size: 16px;
}

.gfs_textarea_sentence {
  width: 100%;
  height: 150px;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  margin: auto;
  display: block;
  padding: 5px 10px;
  font-size: 16px;
  line-height: 1.8;
}

.gfs_error_title_message {
  margin-top: -10px;
  margin-left: 10px;
  text-align: left;
  color: red;
}

.gfs_error_messages {
  width: 83%;
  margin: auto;
  text-align: left;
  color: red;
}

.gfs_error_messages span {
  width: 83%;
  margin: auto;
  text-align: left;
  color: red;
  font-size: 11.5px;
}

.gfs_textarea_title_errored {
  width: 83%;
  height: 47px;
  border: 1px solid red;
  border-radius: 5px;
  margin: auto;
  display: block;
}

.gfs_textarea_sentence_errored {
  width: 83%;
  height: 150px;
  border: 1px solid red;
  border-radius: 5px;
  margin: auto;
  display: block;
}

.gfs_right_pointer {
  float: right;
  height: 25px;
  margin-top: -2px;
}

.gfs_inputedText {
  text-align: left;
}

.registTextbox,
.gfs_registTextbox {
  margin-bottom: 15px;
}

.gfs_assist_visible_non {
  display: none;
}

.gfs_assist_visible_non_non {
  visibility: hidden;
  display: none;
}

.gfs_assist_visible {
  display: block;
  margin-top: 20px;
}

.coopbbsButton.gfs_color_gray {
  color: #fff;
  background-color: #b9b9b9;
}

.st0 {
  fill: none !important;
}
/* GFS追加　- ここまで　- */
