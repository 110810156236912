@charset "UTF-8";

.registInput_kanaColumn-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 20px;
}

.registInput_kanaColumn {
  text-decoration: underline;
  font-size: 18px;
  cursor: pointer;
}
.registInput_kanaColumn.current {
  font-weight: bold;
  text-decoration: none;
  cursor: inherit;
}

.registTextbox_title-wrap {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.registTextbox_title-wrap .requireText {
  margin-left: auto;
  font-size: 14px;
}
